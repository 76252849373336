/*
  H2 Heading with Line underneath
*/
import React from 'react'

const SubTitle = ({ children, className }) => (
  <div className="mx-10 sm:mx-20">
    <h2
      className={`font-title font-bold text-2xl sm:text-4xl mb-3 mt-20 ${className}`}
    >
      {children}
    </h2>
    <div className="bg-black h-[4px] w-[30px] mb-10"></div>
  </div>
)

export default SubTitle
