import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import SubSubTitle from './Titles/SubSubTitle'

const ProjectCard = ({ project, lang }) => {
  const translations = project.translations.find((t) => t.language === lang)
  const link = lang === 'de' ? '/de' : ''

  return (
    <article
      className="w-full mb-2 bg-gray-100 cursor-pointer"
      key={project.slug}
    >
      <Link to={link + '/projects/' + project.slug} className="no-underline">
        {project.images &&
          project.images[0] &&
          project.images[0].localFile &&
          project.images[0].localFile.childImageSharp && (
            <GatsbyImage
              image={getImage(project.images[0].localFile.childImageSharp)}
              className="h-[200px]"
              alt={translations.title + ' title image'}
            />
          )}
        <div className="p-5 text-black">
          <SubSubTitle>
            {translations.title} ({project.year})
          </SubSubTitle>
          <p
            className="text-sm font-text"
            dangerouslySetInnerHTML={{
              __html: `${translations.description.substring(
                0,
                Math.min(130, translations.description.length)
              )} ..`,
            }}
          />
        </div>
      </Link>
    </article>
  )
}

export default ProjectCard
