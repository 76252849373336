/*
  H2 Heading with Line underneath
*/
import React from 'react'

const SubSubTitle = ({ children, className, white }) => (
  <div className={className}>
    <h3
      className={`font-title font-bold text-lg mb-1 ${
        white ? 'text-white' : 'text-black'
      }`}
    >
      {children}
    </h3>
    <div
      className={`h-[2px] w-[30px] mb-3 ${white ? 'bg-white' : 'bg-black'}`}
    ></div>
  </div>
)

export default SubSubTitle
